import React from 'react';
import styled from 'styled-components';

import Faq from '@src/templates/faq';

export default function Overview() {
  return (
    <Faq location={{ pathname: '/faq/overview' }}>
      <h1>Frequently Asked Questions</h1>
      <Description>
        <p>Welcome to our FAQ Section!</p>
        <p>{`Got questions? We've got answers! Here, you'll find helpful information to guide you through our products, services, and policies.`}</p>
        <p>{`Whether you're looking for troubleshooting tips, general information, or specific details about your account, we've gathered the most commonly asked questions to provide quick and easy
        solutions.`}</p>
        <p>{`If you can't find what you're looking for, feel free to reach out to our support team — we're always here to help!`}</p>
      </Description>
    </Faq>
  );
}

const Description = styled.p`
  margin-bottom: 24px;
`;
